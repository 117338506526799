import {requestApi} from "@/services/shared/requestApi";
import { ResponseData } from "@/interfaces/response";
import { Event } from "@/interfaces/vipInterfaces";


const addEvent = async (data:any) => {
    const resp : Event = await requestApi("post","/api/billing/Vip/AddEventPassengers",data) || {} as Event
    return resp;
}

const putEvent = async (data:any) => {
    const resp : Event = await requestApi("put","/api/billing/Vip/AddEvent",data) || {} as Event
    return resp;
}

const getEvent = async (data:any) => {
    const resp : Event = await requestApi("post","/api/billing/Vip/GetEvent",data) || {} as Event
    return resp;
}


export {
    addEvent, 
    putEvent,
    getEvent
}