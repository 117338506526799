import Vue from "vue";
import Vuex from "vuex";

import SharedModule from "./shared";
import { SharedState } from "./shared/state";

export interface StateInterface {
  Shared: SharedState;
}
Vue.use(Vuex);

export default new Vuex.Store<StateInterface>({
  modules: {
    Shared: SharedModule,
  },
});
