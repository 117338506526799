import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import BACEventFormView from "../views/BACEventFormView.vue";
import BacEventFormFinishView from "../views/BacEventFormFinishView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: "/", component: BacEventFormFinishView },
  { path: "/about", component: AboutView },
  { path: "/home", component: HomeView },
];

const router = new VueRouter({
  routes,
});

export default router;
