
import Vue from "vue";

export default Vue.extend({
  name: "SnackBar",
  props: {
    model: {
      type: Boolean,
    },
    color: {
      type: String,
    },
    text: {
      type: String,
    },
    timeout: {
      type: Number,
      default: 3000,
    },
    elevation: {
      type: String,
      default: "5",
    },
  },
  data() {
    return {
      snackBarModel: this.$props.model,
    };
  },
  watch: {
    model(newVal) {
      this.$data.snackBarModel = newVal;
    },
  },
});
