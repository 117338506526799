
import Vue from "vue";
import { mapMutations } from "vuex";
import HelloWorld from "../components/HelloWorld.vue";

export default Vue.extend({
  name: "HomeView",
  components: {
    HelloWorld,
  },
  created() {
    this.changeTitle("");
  },
  methods: {
    ...mapMutations("Shared", ["changeTitle"]),
  },
});
