
import Vue from "vue";
import { mapMutations } from "vuex";

export default Vue.extend({
  name: "App",
  components: {},
  data() {
    return {
      // status: useAuth().authStatus
    };
  },

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    title() {
      return this.$store.state.Shared.titleDash;
    },
    isLoading() {
      return this.$store.state.Shared.isLoading;
    },
  },
  methods: {
    setDrawerState() {
      this.stateDrawer(true);
    },
    ...mapMutations("Shared", ["stateDrawer", "userLogin"]),
  },
});
