import { MutationTree } from "vuex";
import { SharedState } from "./state";

const mutation: MutationTree<SharedState> = {
  changeTitle(state, newTitle) {
    state.titleDash = newTitle;
  },
  stateLoading(state, newState) {
    state.isLoading = newState;
  },
  stateDrawer(state) {
    state.isDrawer = !state.isDrawer;
  },
  userLogin(state, newState) {
    state.isUserLogin = newState;
  },
};

export default mutation;
