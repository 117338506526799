
import Vue from "vue";
import { Event, EventPassenger } from "@/interfaces/vipInterfaces";
import SnackBar from "@/components/shared/SnackBar.vue";
import { addEvent } from "@/services/vipServices/vipParams";

export default Vue.extend({
  name: "eventFormFinish",
  components: {
    SnackBar,
  },
  data() {
    return {
      screenWidth: window.innerWidth,
      snackBarModel: false,
      snackBarColor: "danger",
      snackBarText: "",
      saving: false,
      date: "13-Aug-23",
      itemsSite: ["Purdy Paseo Colón", "Purdy Center Escazú"],
      timeOptions: ["3:00 pm", "3:30 pm", "4:00 pm", "4:30 pm"],
      selectedOption: "",
      headereventPassengers: [
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Teléfono",
          value: "phone",
        },
        {
          text: "Correo",
          value: "email",
        },
        {
          text: "Acciones",
          value: "actions",
        },
      ],
      actionsTable: [
        {
          icon: "mdi-pencil",
          iconCText: "white",
          iconColor: "warning",
          event: "edit-item",
          title: "Editar",
        },
        {
          icon: "mdi-pencil",
          iconCText: "white",
          iconColor: "error",
          event: "delete-item",
          title: "Eliminar",
        },
      ],
      eventPassengers: [] as Array<EventPassenger>,
      activeActions: [],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {} as EventPassenger,
      formTitle: "Agregar Persona Adicional",
      event: {} as Event,
      owner: {} as EventPassenger,
      requiredRule: [
        (v: any) =>
          (!!v && (Object.keys(v) || v).length > 0) || "Campo requerido",
      ],
      emailValidRule : [
        (v: any) =>
          (!!v && (Object.keys(v) || v).length > 0) || "Campo requerido", 
        (v: string) =>
          /.+@.+\..+/.test(v) || "Formato de correo electrónico inválido",
      ],
      phoneValidRule : [
        (v: any) =>
          (!!v && (Object.keys(v) || v).length > 0) || "Campo requerido", 
        (v: string) =>
          /^\d{8}$/.test(v) || "Ingrese un número de teléfono válido (8 dígitos)",
      ],
    };
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    selectOption(option: any) {
      this.selectedOption = option;
      console.log(option);
    },
    editItem(item: any) {
      this.editedIndex = this.eventPassengers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.formTitle = "Editar Persona Adicional";
      this.dialog = true;
    },
    deleteItem(item: any) {
      this.editedIndex = this.eventPassengers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.eventPassengers.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        (this.editedItem = {} as EventPassenger), (this.editedIndex = -1);
        this.formTitle = "Agregar Persona Adicional";
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        (this.editedItem = {} as EventPassenger), (this.editedIndex = -1);
      });
    },
    saveEventPassenger() {
      let form: any = this.$refs.formGuest;
      if (form != undefined) {
        if (form.validate()) {
          if (this.editedIndex > -1) {
            Object.assign(
              this.eventPassengers[this.editedIndex],
              this.editedItem
            );
          } else {
            if (this.eventPassengers.length < 4) {
              this.eventPassengers.push(this.editedItem);
            } else {
              this.snackBarModel = true;
              this.snackBarText =
                "Ha sobrepasado el límite de las personas adicionales";
              this.snackBarColor = "error accent-2";
            }
          }
          form.resetValidation()
          this.close();
        } else {
          this.snackBarModel = true;
          this.snackBarText = "Ingrese los datos requeridos";
          this.snackBarColor = "error accent-2";
        }
      }
      setTimeout(() => {
        this.snackBarModel = false;
        this.saving = false;
      }, 5000);
    },
    async saveEvent() {
      if (this.saving) {
        return; // Evitar doble clic si ya se está guardando
      }
      this.saving = true;
      let form: any = this.$refs.formEvent;
      if (form != undefined) {
        if (form.validate()) {
          this.event.name = this.owner.name;
          this.event.phone = this.owner.phone;
          this.event.email = this.owner.email;

          let hours = Number(this.selectedOption.split(":")[0]) + 12;
          let minutes = this.selectedOption.split(":")[1];
          let hourValue = this.$data.event.date + "T" + hours + ":" + minutes.split(" ")[0] + ":00Z";
          this.$data.event.hour = hourValue

          this.event.additionalGuests = this.eventPassengers;
          
          await addEvent(this.event)
          .then(()=> {
            this.snackBarModel = true;
            this.snackBarText = "Evento registrado correctamente";
            this.snackBarColor = "success accent-2";
            form.resetValidation()
            this.initData()
          })
          .catch(()=> {
            this.snackBarModel = true;
            this.snackBarText = "Hubo un error al registrar el evento";
            this.snackBarColor = "error accent-2";
          })
        } else {
          this.snackBarModel = true;
          this.snackBarText = "Ingrese los datos requeridos";
          this.snackBarColor = "error accent-2";
        }
      }
      setTimeout(() => {
        this.snackBarModel = false;
        this.saving = false;
      }, 5000);
    },
    initData(){
      this.eventPassengers = [] as Array<EventPassenger>,
      this.owner = {} as EventPassenger
      this.event = {} as Event
      this.$data.event.date = "2023-08-13";
      this.event.idEvent = 1;
      this.selectedOption = this.timeOptions[0];
    }
  },
  created() {
   this.initData()
  },
  computed: {
    containerImage() {
      if (this.screenWidth <= 768) {
        return 'small-screen';
      } else {
        return 'large-screen';
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
});
