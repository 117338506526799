import { ActionTree } from "vuex";
import { SharedState } from "./state";
import { StateInterface } from "../index";

const actions: ActionTree<SharedState, StateInterface> = {
  some() {
    console.log("entro");
  },
};

export default actions;
