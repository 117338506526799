import { GetterTree } from "vuex";
import { SharedState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<SharedState, StateInterface> = {
  isDrawerS(state) {
    return state.isDrawer;
  },
};

export default getters;
